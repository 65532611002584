.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.landing-page .download-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.landing-page .download-links img {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-width: auto;
    min-height: auto;
}

.landing-page .app-screenshots {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.landing-page .app-screenshots img {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    max-width: 600px;
    max-height: 600px;
    min-width: auto;
    min-height: auto;
}