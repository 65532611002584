
.navbar {
  background-color: #3c73a8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar .logo-container {
  justify-content: flex-start;
}

.navbar .logo-container .logo {
  width: 50px;
  height: 50px;
  border-radius: 20%;
}

.navbar .links-container {
  justify-content: flex-end;
}

.navbar .links-container a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.navbar .links-container a.active {
  background-color: #fff;
  color: #333;
}
