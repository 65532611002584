.page {
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    // height: calc(100vh - 70px);
    // flex-wrap: wrap;
    // gap: 1em;
}

.page h1 {
    color:black;
    font-weight: bold;
}

.page h2 {
    color:black;
}

.page h4 {
    color:black;
}